html,
body {
  background-image: url("/assets/images/background.jpg");
  background-size: cover;
  min-height: 100vh;
}

.background {
  margin: 0px auto;
  text-align: center;
}

.logo {
  margin-top: 10vh;
  height: 10vh;
  margin-bottom: 2vh;
}

.steps {
  color: white;
  text-align: right;
  margin: 5px auto;
  max-width: 600px;
  font-weight: bolder;
}
.contentArea {
  background: white;
  margin: 5px auto;
  max-width: 600px;
}

.mainTitle {
  font-size: 1.6rem;
  padding-top: 50px;
  margin-bottom: 15px;
  font-weight: 700;
}

.subTitle {
  font-size: 0.85rem;
  color: #888;
  margin-bottom: 50px;
}

.emailArea {
  width: 80%;
  justify-content: center;
}

.terms {
  color: #888;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

span {
  color: #194c91;
  text-decoration: underline;
}

.buttonArea {
  background: #194c91;
  width: 100%;
  height: auto;
  color: #194c91;
  bottom: 0;
}

.poweredBy {
  color: white;
  font-size: small;
  font-weight: 600;
}

.labelLeft {
  text-align: left;
  font-size: 12px;
  color: #888;
  text-transform: uppercase;
  font-weight: 600;
}

.form-control {
  background-color: rgb(235, 235, 235) !important;
  border: transparent !important;
  padding: 15px !important;
}

.primary-button {
  color: #194c91;
}

.adyen-checkout__label__text {
  text-align: left;
  font-size: 10px;
  color: #888;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.btn {
  font-size: small;
  font-weight: 500;
}
